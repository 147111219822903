<mat-card class="card column-1">
  <mat-card-content class="project-list-view-card-content">
    <div class="filters">
      <mat-form-field class="no-bottom filter dense-minimum with-floating-label">
        <mat-label>Filter</mat-label>
        <input #filter [(ngModel)]="filterValue" matInput type="text">
        <button (click)="clearFilter()" aria-label="Clear" mat-icon-button matSuffix>
          @if (filterLoading) {
            <mat-spinner class="progress-spinner" diameter="24" matSuffix></mat-spinner>
          } @else {
            <mat-icon [class.empty]="isFilterEmpty()">close</mat-icon>
          }
        </button>
      </mat-form-field>

      <mat-button-toggle-group (change)="onStatusChange()" [(ngModel)]="filterStatuses"
                               aria-label="Statuses" multiple name="status">
        <mat-button-toggle disabled>Status</mat-button-toggle>
        <mat-button-toggle *ngFor="let s of statuses" [value]="s">{{ s }}</mat-button-toggle>
      </mat-button-toggle-group>

      <mat-button-toggle-group (change)="onOrderChange()" [(ngModel)]="orderBy"
                               aria-label="Order By" name="orderBy">
        <mat-button-toggle disabled>Order by</mat-button-toggle>
        <mat-button-toggle value="accountingId">Accounting Id</mat-button-toggle>
        <mat-button-toggle value="name">Name</mat-button-toggle>
      </mat-button-toggle-group>
      <mat-button-toggle-group (change)="onOrderChange()" [(ngModel)]="orderDirection"
                               aria-label="Order Direction" name="orderDirection">
        <mat-button-toggle value="asc">
          <mat-icon class="small-icon">arrow_upward</mat-icon>
          Ascending
        </mat-button-toggle>
        <mat-button-toggle value="desc">
          <mat-icon class="small-icon">arrow_downward</mat-icon>
          Descending
        </mat-button-toggle>
      </mat-button-toggle-group>

      <button (click)="openDialog()" class="add-project" mat-icon-button>
        <mat-icon>add</mat-icon>
      </button>
    </div>
    @for (group of projectGroups; track group.accountingId; let last = $last) {
      <app-project-list [expanded]="expanded" [projects]="group.projects"></app-project-list>
      @if (!last) {
        <mat-divider></mat-divider>
      }
    }
  </mat-card-content>
</mat-card>
