<div class="dashboard-container">
  <div class="dashboard-layout">
    <div class="row-2">
      <mat-card class="card">
        <mat-card-header>
          <mat-card-title>
            Bookmarks
          </mat-card-title>
        </mat-card-header>
        <mat-card-content class="dashboard-card-content">
          <app-project-list [projects]="projects" [showDeadline]="false"></app-project-list>
          <div *ngIf="projects?.length == 0">Nothing bookmarked yet.</div>
        </mat-card-content>
      </mat-card>
      <mat-card class="card">
        <mat-card-header>
          <mat-card-title>
            Alerts
          </mat-card-title>
        </mat-card-header>
        <mat-card-content class="dashboard-card-content">
          <app-alert-list [alerts]="publicAlerts" [smallIcon]=false></app-alert-list>
          <app-alert-list [alerts]="productionAlerts" [smallIcon]=false></app-alert-list>
          <app-alert-list [alerts]="salesAlerts" [smallIcon]=false></app-alert-list>
          @if (snoozedAlerts().length > 0) {
            <h3 class="snoozed-title">Snoozed alerts</h3>
            <app-alert-list [alerts]="snoozedAlerts" [smallIcon]="false"></app-alert-list>
          }
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>
