import {Component, OnInit, Signal, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {Project, ProjectStatus} from "../../api/model/project";
import {ProjectService} from "../../api/project.service";
import {ProjectAddEditDialogComponent} from "../add-edit-dialog/project-add-edit-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {AlertService} from "../../api/alert.service";
import {AlertGrouped} from "../../api/model/alert";
import {option} from "../../equipment/check-box-filter/check-box-filter.component";

@Component({
  selector: 'app-project-accounting-id-wrap',
  templateUrl: './accounting-id-wrap.component.html',
  styleUrls: ['./accounting-id-wrap.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AccountingIdWrapComponent implements OnInit {
  accountingId: string | undefined;
  projects: Project[] | undefined;
  statuses: string[] = Object.values(ProjectStatus);
  alerts: Signal<AlertGrouped[]> = this.alertService.alertsGroupedByEquipmentSignal;
  sidenavExpanded: boolean = true;
  protected readonly option = option;

  constructor(public dialog: MatDialog,
              private projectService: ProjectService,
              private alertService: AlertService,
              private router: Router,
              private route: ActivatedRoute,
  ) {
  }

  ngOnInit(): void {
    this.route.data
      .subscribe(data => {
        console.log("AccountingIdWrapComponent route data on init", data);
        if (data['projects'].length == 0) {
          this.router.navigate(['/'])
        }
        this.updateProjects(data['projects']);
        this.accountingId = this.projects?.[0]?.accountingId
      });
  }

  refetchProjects() {
    this.projectService.list(undefined, this.accountingId)
      .subscribe(projects => {
        console.log("AccountingIdWrapComponent refetchProjects", projects);
        this.updateProjects(projects);
      });
  }

  changeStatus(project: Project, status: string) {
    this.projectService.updateStatus(project.id, {
      status: status as ProjectStatus
    }).subscribe(updatedProject => {
      const loadedProject = this.projects?.find(p => p.id == updatedProject.id);
      if (loadedProject) loadedProject.status = updatedProject.status;
    })
  }

  openAddTopLevelProjectDialog(): void {
    const dialogRef = this.dialog.open(ProjectAddEditDialogComponent, {
      data: {project: {accountingId: this.accountingId}},
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        const project = result as Project
        this.router.navigate(["/project/" + project.accountingId + "/" + project.id])
        this.refetchProjects();
      }
    });
  }

  onUpdate() {
    console.log("AccountingIdWrapComponent onUpdate fired!");
    this.router.navigate([this.router.url]);
  }

  toggleSidenav() {
    this.sidenavExpanded = !this.sidenavExpanded;
  }

  private updateProjects(projects: Project[]) {
    this.projects = projects;
  }
}
