<h2>Bulk Import Projects and Equipment</h2>
<p>
  Provide a comma separated values (CSV) file in the format provided in
  <a [href]="exampleCsvData" download="example-project-import.csv">this example file</a>. Make sure to use semicolon
  character (<strong>;</strong>) as separator.
</p>

<div>
  <input #csvInput (change)="csvInputChange($event)"
         accept=".csv" hidden="hidden" onclick="this.value=null" type="file"/>
  <button (click)="csvInput.click()" color="primary" mat-flat-button>Choose CSV file</button>
</div>

<p *ngIf="apiError">
  <mat-error class="api-error">
    {{ apiError }}
  </mat-error>
</p>

<div *ngIf="imported">
  <h2>Successful import</h2>
  <h3>Source file</h3>
  <p>
    filename: {{ imported.file.name }}<br/>
    size: {{ imported.file.size }} bytes
  </p>
  <h3>Imported {{ imported.projects.length }} projects</h3>
  <div *ngFor="let p of imported.projects" class="imported-project">
    <app-project-list [projects]="[p]"></app-project-list>
    <app-equipment-condense-table [equipment]="p.equipment"></app-equipment-condense-table>
  </div>

</div>
