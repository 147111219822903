import {Pipe, PipeTransform} from '@angular/core';
import _ from "lodash";
import moment from "moment";

export function makeDateReadable(date: Date): string {
  return moment(date).format('YYYY-MM-DD');
}

export function makeReadable(original: string): string {
  return _.startCase(_.toLower(original.replaceAll("_", " ")))
    .replaceAll("Pdi", "PDI")
    .replaceAll("Dfc", "DFC");
}

@Pipe({
  name: 'readable'
})
export class ReadablePipe implements PipeTransform {
  transform(original: string): string {
    return makeReadable(original);
  }
}
