import {AlertDetailed} from "./alert";

export interface WebSocketResponse {
  type: WebSocketReponseType;
  data: AlertDetailed[];
}

export enum WebSocketReponseType {
  ALERTS = "ALERTS",
}
