import {Component, computed, Input, Signal} from '@angular/core';
import {Project} from "../../api/model/project";
import {AlertGrouped} from "../../api/model/alert";

@Component({
  selector: 'app-project-alert-icon',
  templateUrl: './project-alert-icon.component.html',
  styleUrls: ['./project-alert-icon.component.sass']
})
export class ProjectAlertIconComponent {
  @Input() project!: Project;
  @Input() alerts!: Signal<AlertGrouped[]>;
  projectAlerts = computed(() => {
    return this.alerts().filter(a => a.project.id == this.project.id);
  });
}
