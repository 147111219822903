import {Component, computed, Input, signal, Signal} from '@angular/core';
import {AlertGrouped, AlertSeverity, isAlertForEquipment} from "../../api/model/alert";
import {SnoozeAlertDialogComponent, SnoozeAlertDialogData} from "../snooze-alert-dialog/snooze-alert-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {Equipment} from "../../api/model/equipment";

@Component({
  selector: 'app-alert-list',
  templateUrl: './alert-list.component.html',
  styleUrls: ['./alert-list.component.sass']
})
export class AlertListComponent {
  @Input() alerts!: Signal<AlertGrouped[]>;
  @Input() equipment!: Equipment | undefined;
  alertsFiltered = computed(() => this.alerts().filter(a => isAlertForEquipment(a, this.equipment)))
  @Input() linked = true;
  @Input() menuItem = false;
  @Input() projectName = true;
  @Input() smallIcon = true;
  alertLevelEnum: typeof AlertSeverity = AlertSeverity;

  constructor(
    private dialog: MatDialog,
  ) {
  }

  forIcon(alert: AlertGrouped): Signal<AlertGrouped[]> {
    return signal([alert]);
  }

  openSnoozeDialog(alert: AlertGrouped) {
    const dialogData: SnoozeAlertDialogData = {
      alert: alert,
    }
    const dialogRef = this.dialog.open(SnoozeAlertDialogComponent, {data: dialogData});
    dialogRef.afterClosed().subscribe(result => {
      console.log("AlertListComponent closed", result);
    });
  }
}
