import {Component, HostListener, Inject, Signal, ViewEncapsulation} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {EMPTY, merge, Observable, startWith} from "rxjs";
import moment from "moment/moment";
import {EquipmentService} from "../../api/equipment.service";
import {
  Equipment,
  EquipmentCreateUpdateRequest,
  EquipmentGroup,
  EquipmentStatus,
  isPendingOrder,
  isPendingOrderOrOrdred,
  isReceived,
  ItemStatus
} from "../../api/model/equipment";
import {map} from "rxjs/operators";
import {EquipmentCatalogService} from "../../api/equipment-catalog.service";
import {EquipmentCatalog} from "../../api/model/equipment-catalog";
import {MatAutocompleteSelectedEvent} from "@angular/material/autocomplete";

import {MatSnackBar} from "@angular/material/snack-bar";
import {AlertService} from "../../api/alert.service";
import {AlertDetailed} from "../../api/model/alert";
import {ComponentCanDeactivate} from "../../pending-changes.guard";
import {PageEvent} from "@angular/material/paginator";
import {Project, ProjectStatus} from "../../api/model/project";
import {apiErrorToMessage, datepickerDefaultToFriday} from "../../util";
import {ProjectService} from "../../api/project.service";
import {makeReadable} from "../../readable.pipe";
import _ from "lodash";

@Component({
  selector: 'app-equipment-add-edit-dialog',
  templateUrl: './equipment-add-edit-dialog.component.html',
  styleUrls: ['./equipment-add-edit-dialog.component.sass'],
  encapsulation: ViewEncapsulation.None,
})
export class EquipmentAddEditDialogComponent implements ComponentCanDeactivate {
  alerts: Signal<AlertDetailed[]> = this.alertService.alertsSignal;
  itemStatuses: ItemStatus[] = Object.values(ItemStatus);
  allEquipment: EquipmentCatalog[] = [];
  filteredEquipment: Observable<EquipmentCatalog[]>;
  statuses: string[] = Object.values(EquipmentStatus);
  verb = this.data.equipment ? "Save" : "Create";
  addEditForm = new FormGroup({
    sku: new FormControl(this.data.equipment?.sku, [Validators.required]),
    manufacturer: new FormControl(this.data.equipment?.manufacturer, [Validators.required]),
    model: new FormControl(this.data.equipment?.model, [Validators.required]),
    description: new FormControl(this.data.equipment?.description),
    hyperlink: new FormControl(this.data.equipment?.hyperlink),

    projectCode: new FormControl(this.data.equipment?.projectCode, [Validators.required]),
    quantity: new FormControl(this.data.equipment?.quantity, [Validators.required, Validators.min(1)]),
    splitQty: new FormControl(0),
    notes: new FormControl(this.data.equipment?.notes),

    status: new FormControl(this.data.equipment?.status || EquipmentStatus.PENDING_ORDER),
    responsible: new FormControl<string[]>(this.data.equipment?.responsible || []),

    diffFromContract: new FormControl(this.data.equipment?.diffFromContract),

    estimatedShipByDate: new FormControl(this.data.equipment?.estimatedShipByDate),
    receivedOnDate: new FormControl(this.data.equipment?.receivedOnDate),

    purchaseOrderNumber: new FormControl(this.data.equipment?.purchaseOrderNumber),
    purchaseOrderDate: new FormControl(this.data.equipment?.purchaseOrderDate),
    salesOrderConfirmationNumber: new FormControl(this.data.equipment?.salesOrderConfirmationNumber),
    salesOrderConfirmationDate: new FormControl(this.data.equipment?.salesOrderConfirmationDate),
    invoiceNumber: new FormControl(this.data.equipment?.invoiceNumber),
    invoiceDate: new FormControl(this.data.equipment?.invoiceDate),
    installedDate: new FormControl(this.data.equipment?.installedDate),
    warrantyDate: new FormControl({value: this.data.equipment?.warrantyDate, disabled: true}),

    items: this.fb.array<FormGroup>([]),
  })
  apiError: string | undefined;
  alsoSaveToCatalog: boolean = false;
  serialNumbersNotAvailable: boolean = false;
  alsoSplit: boolean = false;
  equipmentGroupIsService: boolean = false;
  itemsPageSize: number = 5;
  itemsPageIndex: number = 0;
  project: Project;
  private defaultVisOptions = {
    "dates": true, "serialNumbers": true
  };
  visibilityOptions = this.defaultVisOptions;
  // if someone has to change this again there's something seriously wrong with this world
  private maxSerialNumbers = 2000;

  constructor(
    private alertService: AlertService,
    private projectService: ProjectService,
    private equipmentService: EquipmentService,
    private equipmentCatalogService: EquipmentCatalogService,
    public dialogRef: MatDialogRef<EquipmentAddEditDialogComponent>,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: EquipmentAddEditDialogData,
    private _snackBar: MatSnackBar,
  ) {
    this.restoreColumnVisibility()
    this.itemsPageSize = Number(localStorage.getItem("itemsPageSize")) || 5;
    console.log("equipment add-edit dialog opened with data", this.data);
    dialogRef.disableClose = true;
    this.filteredEquipment = merge(this.addEditForm.controls.sku.valueChanges, this.addEditForm.controls.model.valueChanges)
      .pipe(
        startWith(''),
        map(state => (state ? this._filterEquipment(state).slice(0, 20) : [])),
      );
    equipmentCatalogService.list(this.data.group).subscribe((eqs) => {
      this.allEquipment = eqs;
    });
    this.addEditForm.controls.status.valueChanges.subscribe(value => {
      if (isReceived(value) && this.addEditForm.controls.receivedOnDate.value == null) {
        this.addEditForm.controls.receivedOnDate.setValue(new Date(), {emitEvent: false});
      }
      if (value) {
        const itemStatus = this.itemStatuses.find(s => s as string == value as string);
        if (itemStatus) {
          let statusChanged = false;
          this.items.controls.forEach(formArray => {
            const control = formArray.controls["statuses"];
            const value = control.value || [];
            if (!value.includes(itemStatus)) {
              statusChanged = true;
              value.push(itemStatus);
              control.setValue(value, {emitEvent: false});
            }
          });
          if (statusChanged) {
            this._snackBar.open(`Physical item statuses updated.`, undefined, {duration: 8000});
          }
        }
      }
    });
    merge(
      this.addEditForm.controls.purchaseOrderNumber.valueChanges,
    ).subscribe(value => {
      if (value && this.addEditForm.value.status == EquipmentStatus.PENDING_ORDER) {
        if (this.addEditForm.controls.purchaseOrderDate.value == null) {
          this.addEditForm.controls.purchaseOrderDate.setValue(new Date(), {emitEvent: false});
        }
        this.addEditForm.controls.status.setValue(EquipmentStatus.PENDING_INFO);
        this._snackBar.open('Status changed to PENDING_INFO as purchase order number was entered',
          undefined, {duration: 8000});
      }
    });
    merge(
      this.addEditForm.controls.estimatedShipByDate.valueChanges,
      this.addEditForm.controls.salesOrderConfirmationNumber.valueChanges,
    ).subscribe(value => {
      if (
        (isPendingOrder(this.addEditForm.value.status)) &&
        this.addEditForm.controls.estimatedShipByDate.value && this.addEditForm.controls.salesOrderConfirmationNumber.value
      ) {
        this.addEditForm.controls.status.setValue(EquipmentStatus.ORDERED);
        this._snackBar.open('Status changed to ORDERED as estimated ship by and SOC number fields were entered',
          undefined, {duration: 8000});
      }
    });
    this.addEditForm.controls.receivedOnDate.valueChanges.subscribe(value => {
      if (value && isPendingOrderOrOrdred(this.addEditForm.value.status)) {
        this.addEditForm.controls.status.setValue(EquipmentStatus.IN_STOCK);
        this._snackBar.open('Status changed to RECEIVED as received on date was entered',
          undefined, {duration: 8000});
      }
    });
    this.addEditForm.controls.quantity.valueChanges.subscribe(value => {
      if (!this.serialNumbersNotAvailable) this.populateItemsForm(value || 0);
    });
    if (this.data.equipment) {
      this.populateItemsForm(this.data.equipment.quantity);
      if (this.data.equipment.items?.length === 1) {
        this.serialNumbersNotAvailable = this.data.equipment.items[0].serialNumber === "N/A";
        if (this.serialNumbersNotAvailable) this.toggleSerialNumberNotAvailable(true);
      }
    }
    if (this.data.group == EquipmentGroup.SERVICE) {
      this.equipmentGroupIsService = true;
      this.addEditForm.controls.projectCode.clearValidators();
    }
    this.project = data.project;
    this.onProjectLoad(data.project);
    this.projectService.get(data.project.id).subscribe(p => {
      this.project = p;
      this.onProjectLoad(this.project);
    });
  }

  get items(): FormArray<FormGroup> {
    return this.addEditForm.controls.items;
  }

  get itemsCurrentPage(): FormGroup[] {
    const firstItemIndex = this.itemsPageIndex * this.itemsPageSize;
    return this.addEditForm.controls.items.controls.slice(firstItemIndex, firstItemIndex + this.itemsPageSize);
  }

  onProjectLoad(project: Project) {
    if (project.locked) {
      this.addEditForm.controls.sku.disable();
      this.addEditForm.controls.manufacturer.disable();
      this.addEditForm.controls.model.disable();
      this.addEditForm.controls.projectCode.disable();
      this.addEditForm.controls.quantity.disable();
      this.addEditForm.controls.description.disable();
      this.addEditForm.controls.hyperlink.disable();
    }
  }

  datePickerValueChange($event: Date, formControlChange: string) {
    datepickerDefaultToFriday(this.addEditForm.get(formControlChange), $event);
  }

  itemsIndex(indexOnCurrentPage: number): number {
    return this.itemsPageIndex * this.itemsPageSize + indexOnCurrentPage;
  }

  @HostListener('window:keyup.esc') onKeyUp() {
    this.dialogRef.close();
  }

  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {
    return !this.addEditForm.dirty;
  }

  onCancelClick() {
    this.dialogRef.close();
  }

  onPrimaryClick() {
    this.onSubmit()
  }

  onSubmit() {
    if (this.project?.status === ProjectStatus.PENDING) {
      alert("You are changing equipment on a PENDING project, no workflow notifications will be triggered. Consider changing project to ACTIVE")
    }
    this.apiError = undefined;
    const formData = this.addEditForm.getRawValue();
    const request = formData as unknown as EquipmentCreateUpdateRequest;
    request.group = this.data.group;
    request.estimatedShipByDate = formData.estimatedShipByDate?.valueOf() || null;
    request.receivedOnDate = formData.receivedOnDate?.valueOf() || null;
    request.purchaseOrderDate = formData.purchaseOrderDate?.valueOf() || null;
    request.salesOrderConfirmationDate = formData.salesOrderConfirmationDate?.valueOf() || null;
    request.invoiceDate = formData.invoiceDate?.valueOf() || null;
    request.installedDate = formData.installedDate?.valueOf() || null;

    (this.data.equipment ? this.equipmentService.update(this.data.equipment.id, request)
      : this.equipmentService.create(this.data.project.id, request))
      .subscribe({
        next: (eq) => {
          console.log("EquipmentAddEditDialog created/updated", eq);
          if (this.alsoSaveToCatalog) {
            this.updateCatalog(eq);
          }
          this.dialogRef.close(eq);
        },
        error: error => {
          console.log("EquipmentAddEditDialog failed to add/edit", error)
          this.apiError = apiErrorToMessage(error);
          return EMPTY;
        }
      });
  }

  toggleSerialNumberNotAvailable(checked: boolean) {
    this.serialNumbersNotAvailable = checked;
    if (checked) {
      this.populateItemsForm(1);
      const firstSerialNumber = this.items.at(0).get('serialNumber');
      firstSerialNumber?.setValue("N/A");
      firstSerialNumber?.disable();
    } else {
      this.populateItemsForm(this.addEditForm.controls.quantity.value || 0);
      const firstSerialNumber = this.items.at(0).get('serialNumber');
      firstSerialNumber?.setValue("");
      firstSerialNumber?.enable();
    }
  }

  skuAutocompleteSelect($event: MatAutocompleteSelectedEvent) {
    const found = this.allEquipment.find(value => value.id == $event.option.id)
    if (found) {
      this.addEditForm.controls.sku.setValue(found.sku);
      this.addEditForm.controls.model.setValue(found.model);
      this.addEditForm.controls.manufacturer.setValue(found.manufacturer);
      this.addEditForm.controls.description.setValue(found.description);
      this.addEditForm.controls.hyperlink.setValue(found.hyperlink);
    }
  }

  onDeleteClick() {
    if (prompt("Are you sure you want to delete? Action is irreversible. Type 'yes' to continue") == 'yes') {
      this.equipmentService.delete(this.data.equipment!.id).subscribe({
        next: (eq) => {
          console.log("equipment deleted", this.data.equipment!.id, eq);
          this.dialogRef.close(true);
        },
        error: error => {
          this.apiError = apiErrorToMessage(error);
        }
      });
    } else {
      alert("That wasn't a 'yes'... phew.");
    }
  }

  isSameAsCatalog(): boolean {
    // keep field disabled when sku field is empty
    if (!this.addEditForm.value.sku || this.addEditForm.value.sku.length == 0) {
      return true;
    }

    const fromCatalog = this.allEquipment.find(e => e.sku == this.addEditForm.value.sku)
    // FIXME optimise this check/function. Now this gets triggerred so many times (100+ on single modal open and close).
    //  It generally only changes after user input, so hooking up to those events and using a variable could be a viable solution
    // console.log("EquipmentAddEditDialogComponent isSameAsCatalog", this.addEditForm.value, fromCatalog)

    if (fromCatalog && this.addEditForm.value.model == fromCatalog.model
      && this.addEditForm.value.manufacturer == fromCatalog.manufacturer
      && this.addEditForm.value.model == fromCatalog.model
      && (this.addEditForm.value.description || "") == (fromCatalog.description || "")
      && (this.addEditForm.value.hyperlink || "") == (fromCatalog.hyperlink || "")
    ) {
      return true;
    }
    return false;
  }

  statusTooltip(status: ItemStatus, index: number) {
    const authored = this.data.equipment?.items.at(index)?.statusHistory?.find(value => value.status == status)?.authored;
    if (authored) {
      const atParsed = moment(authored.at);
      if (atParsed.unix() > 0) {
        return makeReadable(status) + ` at ${atParsed.format("YYYY-MM-DD")} by ${authored.by} `;
      }
    }
    return "";
  }

  handleSerialNumberPaste($event: ClipboardEvent, i: number) {
    const formArray = this.items;
    let paste = $event.clipboardData?.getData("text") || "";
    if (paste.includes("\n")) {
      $event.preventDefault();
      paste.split("\n").forEach((line, index) => {
        const field = formArray.at(i + index)?.controls["serialNumber"];
        field && field.setValue(line);
      });
    }
  }

  persistColumnVisibility() {
    localStorage.setItem(`equipmentEditModalVisibility`, JSON.stringify(this.visibilityOptions));
  }

  handlePageEvent(e: PageEvent) {
    console.log("EquipmentAddEditDialogComponent handlePageEvent", e);
    this.itemsPageSize = e.pageSize;
    localStorage.setItem("itemsPageSize", this.itemsPageSize.toString());
    this.itemsPageIndex = e.pageIndex;
  }

  highlight(text: string, searchFor: string | undefined | null) {
    const textLower = text.toLowerCase();
    const searchForLower = searchFor?.toLowerCase() || "";
    if (searchForLower.length > 0) {
      const startIndex = textLower.indexOf(searchForLower);
      const endIndex = startIndex + searchForLower.length;
      if (startIndex >= 0) {
        return text.substring(0, startIndex)
          + `<span class="highlight">`
          + text.substring(startIndex, endIndex)
          + `</span>`
          + text.substring(endIndex, text.length);
      }
    }
    return text;
  }

  private updateCatalog(eq: Equipment) {
    this.equipmentCatalogService.bulkWrite([{
      group: this.data.group,
      manufacturer: eq.manufacturer,
      sku: eq.sku,
      model: eq.model,
      description: eq.description,
      hyperlink: eq.hyperlink,
    }]).subscribe(response => console.log("EquipmentAddEditDialogComponent updateCatalog success", response));
  }

  private _filterEquipment(filter: string): EquipmentCatalog[] {
    const f = filter.toLowerCase();
    return this.allEquipment.filter(equipment =>
      equipment.sku.toLowerCase().includes(f) || equipment.model.toLowerCase().includes(f)
    );
  }

  private populateItemsForm(qty: number) {
    qty = qty < this.maxSerialNumbers ? qty : this.maxSerialNumbers;
    const formArray = this.items;
    let initialItemCount = formArray.length;
    if (initialItemCount < qty) {
      const addCount = qty - initialItemCount;
      for (let i = 0; i < addCount; i++) {
        const existingItem = this.data.equipment?.items[initialItemCount + i];
        const existingSerialNumber = existingItem && existingItem.serialNumber || "";
        const existingStatuses = existingItem && existingItem.statusHistory?.map(s => s.status) || [];
        const existingFwVer = existingItem && existingItem.firmwareVersion || null;
        const itemForm = this.fb.group({
          serialNumber: [existingSerialNumber],
          statuses: [existingStatuses],
          firmwareVersion: [existingFwVer],
        });
        itemForm.controls.statuses.valueChanges.subscribe(value => {
          const statuses = this.items.controls.map(i => i.controls["statuses"].value).flat().filter(s => this.statuses.includes(s));
          const counts = _.countBy(statuses);
          const physicalQty = this.serialNumbersNotAvailable ? 1 : this.addEditForm.controls.quantity.value;
          const unanimousStatus = this.itemStatuses.toReversed().find(is => counts[is] === physicalQty);
          const statusToBe = unanimousStatus as String as EquipmentStatus || EquipmentStatus.ORDERED;
          console.log("EquipmentAddEditDialogComponent itemForm.controls.statuses.valueChanges", value, statuses, counts, unanimousStatus, statusToBe);

          if (this.addEditForm.controls.status.value != statusToBe) {
            this._snackBar.open(`Status changed to ${makeReadable(statusToBe)} dictated by physical item statuses.`,
              undefined, {duration: 8000});
            this.addEditForm.controls.status.setValue(statusToBe, {emitEvent: false});
          }
        });
        formArray.push(itemForm);
      }
    } else if (initialItemCount > qty) {
      const removeCount = initialItemCount - qty;
      for (let i = 0; i < removeCount; i++) {
        formArray.removeAt(formArray.length - 1);
      }
    }
  }

  private restoreColumnVisibility() {
    this.visibilityOptions = JSON.parse(localStorage.getItem(`equipmentEditModalVisibility`) || JSON.stringify(this.defaultVisOptions));
  }
}

export interface EquipmentAddEditDialogData {
  project: Project;
  equipment: Equipment | undefined;
  group: EquipmentGroup;
}
