import {isDevMode, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ServiceWorkerModule} from '@angular/service-worker';
import {LoginComponent} from './auth/login/login.component';

import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {LayoutModule} from '@angular/cdk/layout';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatButtonModule} from '@angular/material/button';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatIconModule} from '@angular/material/icon';
import {MatListModule} from '@angular/material/list';
import {DashComponent} from './dash/dash.component';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatCardModule} from '@angular/material/card';
import {MatMenuModule} from '@angular/material/menu';
import {MatTreeModule} from '@angular/material/tree';
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {PageNotFoundComponent} from './page-not-found/page-not-found.component';
import {MatBadgeModule} from "@angular/material/badge";
import {CookieService} from "ngx-cookie-service";
import {ActivityComponent} from './project/activity/activity.component';
import {MatChipsModule} from "@angular/material/chips";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {MatTabsModule} from "@angular/material/tabs";
import {MatTableModule} from "@angular/material/table";
import {MatSortModule} from "@angular/material/sort";
import {MatTooltipModule} from "@angular/material/tooltip";
import {HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi} from "@angular/common/http";
import {ProjectService} from "./api/project.service";
import {AuthService} from "./api/auth.service";
import {TaskService} from "./api/task.service";
import {CommentService} from "./api/comment.service";
import {EquipmentService} from "./api/equipment.service";
import {HealthService} from "./api/health.service";
import {MatDialogModule} from "@angular/material/dialog";
import {AuthInterceptor} from "./api/auth.interceptor";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {DateAdapter, MAT_DATE_FORMATS} from "@angular/material/core";
import {MatMomentDateModule} from "@angular/material-moment-adapter";
import {MatSelectModule} from "@angular/material/select";
import {MatAutocompleteModule} from "@angular/material/autocomplete";
import {BuildDetailsService} from "./api/build.service";
import {MatSnackBarModule} from "@angular/material/snack-bar";
import {EquipmentCatalogComponent} from './settings/equipment-catalog/equipment-catalog.component';
import {ManufacturerComponent} from './settings/manufacturer/manufacturer.component';
import {ManufacturerService} from "./api/manufacturer.service";
import {EquipmentCatalogService} from "./api/equipment-catalog.service";
import {
  ManufacturerEditDialogComponent
} from './settings/manufacturer/manufacturer-edit-dialog/manufacturer-edit-dialog.component';
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {MyDateAdapter} from "./util";
import {MatButtonToggleModule} from "@angular/material/button-toggle";
import {AlertService} from "./api/alert.service";
import {DateInterceptor} from "./api/date.interceptor";
import {BsDatepickerConfig, BsDatepickerModule, BsLocaleService} from "ngx-bootstrap/datepicker";
import {CommentsComponent} from "./project/comments/comments.component";
import {StatusComponent} from "./project/status/status.component";
import {AccountingIdWrapComponent} from "./project/accounting-id-wrap/accounting-id-wrap.component";
import {ProjectMainComponent} from "./project/main/project-main.component";
import {DetailsComponent} from "./project/details/details.component";
import {OverviewComponent} from "./project/overview/overview.component";
import {TagsComponent} from "./project/tags/tags.component";
import {TasksComponent} from "./project/tasks/tasks.component";
import {EquipmentTableComponent} from "./equipment/equipment-table/equipment-table.component";
import {ProjectAddEditDialogComponent} from "./project/add-edit-dialog/project-add-edit-dialog.component";
import {MoveCopyAssignDialogComponent} from "./equipment/move-copy-assign-dialog/move-copy-assign-dialog.component";
import {EquipmentAddEditDialogComponent} from "./equipment/add-edit-dialog/equipment-add-edit-dialog.component";
import {BulkEditDialogComponent} from "./equipment/bulk-edit-dialog/bulk-edit-dialog.component";
import {ProjectListViewComponent} from "./project/list-view/project-list-view.component";
import {defineLocale, ltLocale} from "ngx-bootstrap/chronos";
import {SingleUserComponent} from './user/single-user/single-user.component';
import {DeadlineComponent} from './project/deadline/deadline.component';
import {ProjectListComponent} from './project/list/project-list.component';
import {BreadcrumbComponent} from './breadcrumb/breadcrumb.component';
import {UrlPipe} from './project/url.pipe';
import {CdkContextMenuTrigger, CdkMenu, CdkMenuItem} from "@angular/cdk/menu";
import {UsersComponent} from './settings/users/users.component';
import {UserAddEditDialogComponent} from "./settings/users/add-edit-dialog/user-add-edit-dialog.component";
import {RegisterComponent} from './auth/register/register.component';
import {PasswordResetComponent} from './auth/password-reset/password-reset.component';
import {UserProfileComponent} from './settings/user-profile/user-profile.component';
import {ProjectImportComponent} from './settings/project-import/project-import.component';
import {CondenseTableComponent} from './equipment/condense-table/condense-table.component';
import {UserFieldComponent} from './user/user-field/user-field.component';
import {CheckBoxFilterComponent} from './equipment/check-box-filter/check-box-filter.component';
import {MatSliderModule} from "@angular/material/slider";
import {RmaListComponent} from './service/rma-list/rma-list.component';
import {SingleRmaComponent} from './service/single-rma/single-rma.component';
import {RmaService} from "./api/rma.service";
import {CreateRmaDialogComponent} from './service/create-rma-dialog/create-rma-dialog.component';
import {AlertListComponent} from "./alerts/alert-list/alert-list.component";
import {AlertIconComponent} from "./alerts/alert-icon/alert-icon.component";
import {A11yModule} from "@angular/cdk/a11y";
import {RmaStatusComponent} from "./service/status/rma-status.component";
import {GlobalSearchComponent} from './global-search/global-search.component';
import {StatusHistoryComponent} from './equipment/status-history/status-history.component';
import {CdkConnectedOverlay, CdkOverlayOrigin} from "@angular/cdk/overlay";
import {MatPaginatorModule} from "@angular/material/paginator";
import {ProjectListItemComponent} from './project/list/project-list-item.component';
import {AddChildDialogComponent} from './project/add-child-dialog/add-child-dialog.component';
import {SortablejsModule} from "nxt-sortablejs";
import {PermissionsDialogComponent} from "./project/permissions-dialog/permissions-dialog.component";
import {ReadablePipe} from "./readable.pipe";
import {WebSocketService} from "./api/ws.service";
import {ProjectAlertIconComponent} from "./alerts/project-alert-icon/project-alert-icon.component";
import {SnoozeAlertDialogComponent} from "./alerts/snooze-alert-dialog/snooze-alert-dialog.component";

defineLocale('lt', ltLocale)

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    DashComponent,
    PageNotFoundComponent,
    ActivityComponent,
    EquipmentCatalogComponent,
    ManufacturerComponent,
    ManufacturerEditDialogComponent,
    CommentsComponent,
    StatusComponent,
    AlertListComponent,
    AlertIconComponent,
    ProjectAlertIconComponent,
    // project
    AccountingIdWrapComponent,
    ProjectMainComponent,
    DetailsComponent,
    OverviewComponent,
    TagsComponent,
    TasksComponent,
    EquipmentTableComponent,
    ProjectAddEditDialogComponent,
    MoveCopyAssignDialogComponent,
    EquipmentAddEditDialogComponent,
    BulkEditDialogComponent,
    SnoozeAlertDialogComponent,
    ProjectListViewComponent,
    SingleUserComponent,
    DeadlineComponent,
    ProjectListComponent,
    BreadcrumbComponent,
    UrlPipe,
    ReadablePipe,
    UsersComponent,
    UserAddEditDialogComponent,
    RegisterComponent,
    PasswordResetComponent,
    UserProfileComponent,
    ProjectImportComponent,
    CondenseTableComponent,
    UserFieldComponent,
    CheckBoxFilterComponent,
    RmaListComponent,
    SingleRmaComponent,
    CreateRmaDialogComponent,
    RmaStatusComponent,
    GlobalSearchComponent,
    StatusHistoryComponent,
    ProjectListItemComponent,
    AddChildDialogComponent,
    PermissionsDialogComponent,
  ],
  bootstrap: [AppComponent], imports: [BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
    SortablejsModule.forRoot({animation: 150, delay: 75}),
    MatSlideToggleModule,
    LayoutModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    MatGridListModule,
    MatCardModule,
    MatMenuModule,
    MatTreeModule,
    MatFormFieldModule,
    FormsModule,
    MatInputModule,
    ReactiveFormsModule,
    MatBadgeModule,
    MatChipsModule,
    MatCheckboxModule,
    MatTabsModule,
    MatTableModule,
    MatSortModule,
    MatTooltipModule,
    MatDialogModule,
    MatDatepickerModule,
    MatMomentDateModule,
    MatSelectModule,
    MatAutocompleteModule,
    MatSnackBarModule,
    MatProgressSpinnerModule,
    MatButtonToggleModule,
    MatSidenavModule,
    BsDatepickerModule.forRoot(),
    CdkMenu,
    CdkMenuItem,
    CdkContextMenuTrigger,
    MatSliderModule,
    A11yModule,
    CdkConnectedOverlay,
    CdkOverlayOrigin,
    MatPaginatorModule], providers: [
    HealthService,
    AuthService,
    CookieService,
    ProjectService,
    EquipmentService,
    EquipmentCatalogService,
    ManufacturerService,
    TaskService,
    CommentService,
    BuildDetailsService,
    AlertService,
    RmaService,
    WebSocketService,
    [
      {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
      {provide: HTTP_INTERCEPTORS, useClass: DateInterceptor, multi: true},
    ],
    {provide: DateAdapter, useClass: MyDateAdapter},
    {
      provide: MAT_DATE_FORMATS, useValue: {
        parse: {
          dateInput: 'YYYY-MM-DD',
        },
        display: {
          dateInput: 'YYYY-MM-DD',
          monthYearLabel: 'MMM YYYY',
          dateA11yLabel: 'LL',
          monthYearA11yLabel: 'MMMM YYYY',
        },
      }
    },
    {provide: BsDatepickerConfig, useFactory: getDatepickerConfig},
    provideHttpClient(withInterceptorsFromDi()),
  ]
})
export class AppModule {
  constructor(private bsLocaleService: BsLocaleService) {
    this.bsLocaleService.use('lt');
  }
}

export function getDatepickerConfig(): BsDatepickerConfig {
  return Object.assign(new BsDatepickerConfig(), {
    adaptivePosition: true,
    containerClass: 'theme-sonus mat-datepicker-content',
    dateInputFormat: '[W]W, YYYY-MM-DD',
    isAnimated: true,
    selectWeek: true,
    selectFromOtherMonth: true,
    customTodayClass: 'today'
  })
}
