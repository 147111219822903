@for (a of alertsFiltered(); track a.project.id + a.type) {
  @if (menuItem) {
    <div mat-menu-item class="alert-container" [class.menu-item]="menuItem">
      <span class="flex">
        <ng-container *ngTemplateOutlet="alert"></ng-container>
      </span>
    </div>
  } @else {
    <div class="alert-container flex">
      <ng-container *ngTemplateOutlet="alert"></ng-container>
    </div>
  }
  <ng-template #alert>
    <app-alert-icon [alerts]="forIcon(a)" [equipment]="equipment" [smallIcon]="smallIcon"></app-alert-icon>
    <a [queryParams]="{alert: a.type}" [routerLink]="linked ? (a.project | url) : undefined" class="navigate">
      <strong *ngIf="projectName">{{ a.project.name }} </strong>
      <span>{{ a.message }}</span>
      <span *ngIf="a.culpritEquipmentIds && !equipment && a.culpritEquipmentIds && a.culpritEquipmentIds.length > 0"
      > ({{ a.culpritEquipmentIds.length }})</span>
    </a>
    <button mat-icon-button (click)="openSnoozeDialog(a); $event.stopImmediatePropagation()" class="snooze-button"
            matTooltip="Snooze">
      <mat-icon>snooze</mat-icon>
    </button>
  </ng-template>
}
